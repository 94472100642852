import React, { useState } from 'react';
import SearchAll from '../../SearchAll';

const AppSearch = ({ activeApp, setActiveApp, applications, handleChangeApplication }) => {
  

  return (
    <>
      <div className="appscroll mt-4">
        <p className="apphead">Applications</p>
        <div className="applistparent">

          <div className="applist">
            <div>
              <span
                className={activeApp === 'Applications' ? 'active' : ''}
                onClick={() => setActiveApp('Applications')}
              >
                Applications
              </span>
            </div>

            {applications
              ?.filter((app) => app.resourceType !== null)
              .map((item, index) => {
                return (
                  <div key={index}>
                    <span
                      className={activeApp === item.resourceType?.name ? 'active' : ''}
                      onClick={() => {
                        setActiveApp(item.resourceType?.name);
                        handleChangeApplication(item?.resourceTypeId, item.resourceType?.type);
                      }}
                    >
                      {item.resourceType?.name}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSearch;
