import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import logo from '../../assets/img/sicon.png';
import ValidityForm from './validityform';

const SubValidity = ({combinedData,selectedResources}) => {
    
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
 
    const typeIdMap = new Map();
    selectedResources.forEach(item => {
        if (!typeIdMap.has(item.typeId)) {
            typeIdMap.set(item.typeId, [item]);
        } else {
            typeIdMap.get(item.typeId).push(item);
        }
    });
    
    
    const combinedArray = combinedData.map(item1 => {
        const typeId = item1.resourceTypeId;
        const typeItems = typeIdMap.get(typeId) || [];
        return { ...item1, typeItems };
    });
    
    const filteredData =combinedArray?.filter(item => item.resourceType !== null && item.resourceTypeId !== null);


    return (
    
     <>
    <div className='pagetitletext'>
        {/* <span onClick={() => navigate(-1)}>
            <button className='back'>
                <KeyboardBackspaceIcon />
            </button>
        </span> */}
        Subscriptions
    </div>
    {combinedData ? (
        <div className='row'>
            <div className='col-md-12 mt-3'>
                <div className='col-md-3'>
                    <div className='validhead'>
                        <p className='pagetitletext'>Applications</p>
                        <div className='line'>{""}</div>
                    </div>
                </div>
                <div className='row validity'>
                    {combinedData?.map((item, index) => (
                        <div className='col-md-4' key={index}>
                            <div className="Appbox mt-3">
                                <div className='appthumb'>
                                    <img src={item.icon || ""} alt='' onError={(e) => {
                    e.target.src = logo;
                    e.target.onerror = null; 
                  }} /> 
                                </div>
                                <div className='appdata'>
                                    <div className='apptitle'>
                                        <p>{item.name}</p> 
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ) : null}

    {filteredData.length>0 && filteredData?.map((item, index) => (
        <div className='row' key={index}>

            <div className='col-md-12 mt-4'>
                <div className='col-md-3'>
                    <div className='validhead'>
                        <p className='pagetitletext'>{item?.resourceType?.name}</p>
                        <div className='line'>{""}</div>
                    </div>
                </div>

                <div className='row validity'>
                    {item?.typeItems.map((e, index) => (
                        <div className='col-md-4' key={index}>
                            <div className="Appbox mt-3">
                                <div className='appthumb'>
                                    <img src={e?.versions[0]?.data?.thumbnail || ""} alt=""
                  onError={(e) => {
                    e.target.src = logo;
                    e.target.onerror = null; 
                  }}/>
                                </div>
                                <div className='appdata'>
                                    <div className='apptitle'>
                                        <p>{e?.name}</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ))}
  
</>

    
    
    )


}

export default SubValidity